import React from "react";

import { Box, Flex, Heading, Text, HStack, VStack } from "@chakra-ui/react";

import { Link } from "gatsby";

import {
  FacebookIcon,
  InstagramkIcon,
  LinkdinIcon,
  TwitterIcon,
} from "../../assets/svg/icons";

import Logo from "../../assets/svg/Logo";

const Footer = () => {
  return (
    <Flex mt="200px" justify="center" bg="#0E232D">
      <Flex py="57px" direction="column" w="1158px">
        <Flex w="100%" justify="space-between">
          <Flex w="406px" direction="column">
            <Logo />
            <Text
              opacity="80%"
              fontWeight="medium"
              fontSize="18px"
              color="white"
              mt="20px"
              lineHeight="26px"
            >
              Scale your business and fix your design bottleneck with flat-rate,
              unlimited graphic design and custom illustrations solutions Scale
              your business and fix your design bottleneck with flat-rate,
              unlimited graphic design and custom
            </Text>
            <HStack spacing="17px" mt="30px">
              <FacebookIcon /> <InstagramkIcon /> <LinkdinIcon />{" "}
              <TwitterIcon />
            </HStack>
          </Flex>
          <Flex justify="space-between" w="47%">
            <Flex direction="column">
              <Heading color="white" fontSize="12px" fontWeight="bold">
                COMPANY
              </Heading>
              <VStack
                spacing="19px"
                fontSize="16px"
                fontWeight="medium"
                color="white"
                mt="16px"
                align="start"
                opacity="80%"
              >
                <Link to="/test">
                  <Text>About</Text>
                </Link>
                <Text>Jobs</Text>
                <Text>For The Record</Text>
              </VStack>
            </Flex>
            <Flex direction="column">
              <Heading color="white" fontSize="12px" fontWeight="bold">
                COMMUNITIES
              </Heading>
              <VStack
                spacing="19px"
                fontSize="16px"
                fontWeight="medium"
                color="white"
                mt="16px"
                align="start"
                opacity="80%"
              >
                <Text>Support</Text>
                <Text>Login </Text>
                <Text>Signup</Text>
                <Text>Dashboard</Text>
                <Text>Jobs</Text>
                <Text>For The Record</Text>
              </VStack>
            </Flex>
            <Flex direction="column">
              <Heading color="white" fontSize="12px" fontWeight="bold">
                USEFUL LINKS
              </Heading>
              <VStack
                spacing="19px"
                fontSize="16px"
                fontWeight="medium"
                color="white"
                mt="16px"
                align="start"
                opacity="80%"
              >
                <Text>Support</Text>
                <Text>Login </Text>
                <Text>Signup</Text>
                <Text>Dashboard</Text>
              </VStack>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="space-between" mt="83px">
          <HStack
            color="white"
            fontSize="12px"
            fontWeight="normal"
            opacity="80%"
            spacing="31px"
          >
            <Text>Legal</Text>
            <Text>Privacy Policy</Text>
            <Text>Cookies</Text>
            <Text>Terms</Text>
          </HStack>
          <Text fontSize="12px" fontWeight="normal" opacity="80%" color="white">
            @2020 44Pixels.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
