import React from "react";
import { Flex, Box, Heading, Wrap, WrapItem } from "@chakra-ui/react";

const LogoGrid = () => {
  return (
    <Flex mt="200px" justify="center">
      <Flex direction="column" align="center" w="1158px">
        <Heading fontSize="36px" fontWeight="bold">
          Our designers have worked for
        </Heading>
        <Wrap spacing="107px">
          <WrapItem>
            <Box mt="62px" w="146px" h="38px" bg="gray.200"></Box>
          </WrapItem>
          <WrapItem>
            <Box mt="62px" w="146px" h="38px" bg="gray.200"></Box>
          </WrapItem>
          <WrapItem>
            <Box mt="62px" w="146px" h="38px" bg="gray.200"></Box>
          </WrapItem>
          <WrapItem>
            <Box mt="62px" w="146px" h="38px" bg="gray.200"></Box>
          </WrapItem>
          <WrapItem>
            <Box mt="62px" w="146px" h="38px" bg="gray.200"></Box>
          </WrapItem>
        </Wrap>
      </Flex>
    </Flex>
  );
};

export default LogoGrid;
