import React from "react";

import { Box, Heading, Flex, Wrap, WrapItem } from "@chakra-ui/react";

import Service from "./Service";

const ServicesSection = () => {
  return (
    <Flex mt="200px" justify="center">
      <Flex direction="column" align="center" w="1158px">
        <Heading>What we can do for you</Heading>
        <Wrap mt="100px" justify="center" spacing="126px">
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
          <WrapItem>
            <Service />
          </WrapItem>
        </Wrap>
      </Flex>
    </Flex>
  );
};

export default ServicesSection;
