import React from "react";

import { Flex, Box, Heading, Text, Button } from "@chakra-ui/react";

const Hero = () => {
  return (
    <Flex mx="25px" justify="center">
      <Flex w="1158px" h="600px">
        <Flex direction="column" justify="center" align="start" h="100%">
          <Heading
            as="h1"
            fontSize="48px"
            fontWeight="bold"
            lineHeight="60px"
            color="#0E232D"
            w="444px"
          >
            Scale your business <br /> and fix your{" "}
            <Box color="#E5122E" as="span">
              design bottleneck
            </Box>
          </Heading>
          <Text
            mt="10px"
            fontSize="20px"
            color="#0E232D"
            fontWeight="medium"
            opacity="90%"
          >
            Scale your business and fix your design bottleneck <br /> with
            flat-rate, unlimited graphic design and custom illustrations
            solutions
          </Text>
          <Button
            mt="27px"
            bg="#E5122E"
            _hover={{
              bg: "#71D5BD",
            }}
            color="white"
            w="130px"
            h="48px"

            //   _focus={{
            //     boxShadow:
            //       "0 0 1px 2px rgba(74, 237, 194, 0.6), 0 1px 1px rgba(0, 0, 0, .15)",
            //   }}
          >
            Get Started
          </Button>
        </Flex>
        <Box w="100%" h="100%"></Box>
      </Flex>
    </Flex>
  );
};

export default Hero;
