import React from "react";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";

import Dog from "../../assets/svg/Dog/Dog";
import DogPaws from "../../assets/svg/Dog/DogPaws";

const ContactCTA = () => {
  return (
    <Flex mt="200px" justify="center">
      <Box>
        <Box ml="124px" mt="-120px" position="absolute" zIndex={2}>
          <Dog />
        </Box>

        <Box ml="129px" mt="-6px" position="absolute" zIndex={4}>
          <DogPaws />
        </Box>
      </Box>
      <Flex
        borderRadius="10px"
        justify="center"
        zIndex={3}
        bg="#0E232D"
        w="1091px"
        h="269px"
      >
        <Flex justify="space-between" w="900px">
          <Flex w="556px" justify="center" direction="column">
            <Heading fontSize="39px" color="white">
              Lets Connect
            </Heading>
            <Text
              mt="14px"
              opacity="90%"
              fontSize="16px"
              fontWeight="medium"
              color="white"
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos{" "}
            </Text>
          </Flex>
          <Flex justify="center" align="center">
            <Button
              fontSize="21px"
              fontWeight="semibold"
              bg="#E5122E"
              color="white"
              w="256px"
              h="72px"
              ml="119px"
            >
              Lets Conntect
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactCTA;
