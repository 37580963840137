import React from "react";

import { Box, Flex, Text, Heading } from "@chakra-ui/react";

const Pricing = () => {
  return (
    <Flex mt="200px" justify="center">
      <Flex direction="column" align="center" w="1158px">
        <Heading fontSize="36px">Pricing</Heading>
        <Text lineHeight="25px" mt="17" align="center" w="461px">
          Please note prices below are approximate. I will provide a custom
          quote for you based on your content & unique requirements.
        </Text>
        <Flex w="845px" justify="space-between" mt="60px">
          <Box bg="gray.200" w="355px" h="670px"></Box>
          <Box bg="gray.200" w="355px" h="670px"></Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Pricing;
