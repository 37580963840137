import React from "react";

import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Service = () => {
  return (
    <Flex w="301px" direction="column" align="center">
      <Box w="55px" h="55px" bg="gray.700"></Box>
      <Heading mt="37px" fontSize="19px" fontWeight="bold">
        Logo and branding
      </Heading>
      <Text mt="16px" align="center" lineHeight="25px" fontWeight="normal">
        Custom-built web apps and web portals that solve your business problem
        or make
      </Text>
    </Flex>
  );
};

export default Service;
