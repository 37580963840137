import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="29"
      viewBox="0 0 125 29"
    >
      <text
        id="_44pixels."
        data-name="44pixels."
        transform="translate(0 23)"
        fill="#10545f"
        font-size="29"
        font-family="CircularStd-Black, Circular Std Black"
        font-weight="800"
      >
        <tspan x="0" y="0">
          44
        </tspan>
        <tspan y="0" fill="#55c9ad">
          pixels.
        </tspan>
      </text>
    </svg>
  );
};

export default Logo;
