import React from "react";

import { Icon } from "@chakra-ui/react";

import {
  RiFacebookBoxFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from "react-icons/ri";

export function FacebookIcon() {
  return (
    <Icon opacity="90%" color="white" w={5} h={5} as={RiFacebookBoxFill} />
  );
}

export function InstagramkIcon() {
  return <Icon opacity="90%" color="white" w={5} h={5} as={RiInstagramFill} />;
}

export function LinkdinIcon() {
  return (
    <Icon opacity="90%" color="white" w={5} h={5} as={RiLinkedinBoxFill} />
  );
}

export function TwitterIcon() {
  return <Icon opacity="90%" color="white" w={5} h={5} as={RiTwitterFill} />;
}
