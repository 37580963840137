import React from "react";

import { Heading, Box, Flex, Wrap, WrapItem } from "@chakra-ui/react";

const Portfilio = () => {
  return (
    <Flex mt="200px" justify="center">
      <Flex w="1158px" direction="column" align="center">
        <Heading fontSize="36px" fontWeight="bold">
          Things we have designed lately{" "}
        </Heading>
        <Wrap justify="center" spacing="58px" mt="68px">
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
          <WrapItem>
            <Box w="246px" h="246px" bg="gray.200">
              Box 1
            </Box>
          </WrapItem>
        </Wrap>
      </Flex>
    </Flex>
  );
};

export default Portfilio;
