import * as React from "react";

import { Box, Heading, Button } from "@chakra-ui/react";
import Hero from "../components/Hero/Hero";
import Portfilio from "../components/Portfilio/Portfilio";
import Values from "../components/Values/Values";
import LogoGrid from "../components/LogoGrid/LogoGrid";
import ServicesSection from "../components/ServicesSection/ServicesSection";
import OurGoal from "../components/OurGoal/OurGoal";
import Pricing from "../components/Pricing/Pricing";
import ContactCTA from "../components/ContactCTA/ContactCTA";

// markup
const IndexPage = () => {
  return (
    <Box>
      <Hero />
      <Values />
      <LogoGrid />
      <Portfilio />
      <ServicesSection />
      <OurGoal />
      <Pricing />
      <ContactCTA />
    </Box>
  );
};

export default IndexPage;
