import React from "react";

import { Link } from "gatsby";

import { Box, Flex, HStack, Text, Button } from "@chakra-ui/react";

import Logo from "../../assets/svg/Logo";

const Navbar = () => {
  return (
    <Flex justify="center" w="100%" h="100px">
      <Flex
        justify="space-between"
        align="center"
        w="1158px"
        h="100px"
        mx="25px"
      >
        <Link to="/">
          <Logo />
        </Link>
        <HStack
          color="#10545F"
          fontWeight="semibold"
          fontSize="18px"
          spacing="42px"
          justify="center"
        >
          <Link to="/test">
            <Text>About</Text>
          </Link>
          <Link>
            <Text>Services</Text>
          </Link>
          <Link>
            <Text>Blog</Text>
          </Link>
          <Link>
            <Text>Portfilio</Text>
          </Link>
          <Link>
            <Button
              bg="#E5122E"
              _hover={{
                bg: "#71D5BD",
              }}
              color="white"
              w="121.43px"
              h="48px"

              //   _focus={{
              //     boxShadow:
              //       "0 0 1px 2px rgba(74, 237, 194, 0.6), 0 1px 1px rgba(0, 0, 0, .15)",
              //   }}
            >
              Contact
            </Button>
          </Link>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Navbar;
