import React from "react";
import { Heading, Flex, Text, Box } from "@chakra-ui/react";

const ValueItem = ({ emoji, title, description }) => {
  return (
    <Flex
      justify="center"
      overflow="hidden"
      w="444px"
      h="136px"
      bg="white"
      borderRadius="10px"
    >
      <Flex justify="flex-end" h="100%">
        <Text mt="25px" fontSize="36px">
          {emoji}
        </Text>
      </Flex>
      <Flex ml="14px" justify="center" direction="column" w="260px" h="100%">
        <Text fontSize="19px" fontWeight="bold">
          {title}
        </Text>
        <Text
          fontSize="18px"
          fontWeight="medium"
          opacity="85%"
          lineHeight="26px"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ValueItem;
