import React from "react";

import "./fonts.css";

import { Flex, Box } from "@chakra-ui/react";

import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const Layout = ({ children }) => {
  return (
    <main>
      <Navbar />

      {children}
      <Footer />
    </main>
  );
};

export default Layout;
