import { extendTheme } from "@chakra-ui/react";
// import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  fonts: {
    heading: "sofia_pro, Segoe UI",
    body: "hindd, Segoe UI",
  },
  config: {
    // useSystemColorMode: true,
  },
  colors: {
    // brandPurple: "#673FB4",
  },
  // styles: {
  //   global: (props) => ({
  //     body: {
  //       // fontFamily: "body",
  //       // color: mode("gray.800", "whiteAlpha.900")(props),
  //       bg: mode("#FFFFFF", "#00253F")(props),
  //       // lineHeight: "base",
  //     },
  //   }),
  // },
});

export default theme;
