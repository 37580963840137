import React from "react";

import { Box, Heading, Flex, Button, Text } from "@chakra-ui/react";

import ValueItem from "./ValueItem";

const Values = () => {
  return (
    <Flex justify="center">
      <Flex
        borderRadius="34px"
        px="83px"
        mx="25px"
        w="1280px"
        bg="#0E232D"
        h="653px"
      >
        <Flex
          direction="column"
          align="start"
          justify="center"
          w="50%"
          h="100%"
        >
          <Heading fontSize="50px" color="white">
            Our massive values
          </Heading>
          <Text
            mt="16px"
            mb="34px"
            w="455px"
            color="white"
            fontSize="18px"
            opacity="70%"
            fontWeight="medium"
            lineHeight="28px"
          >
            Scale your business and fix your design bottleneck with flat-rate,
            unlimited graphic design and custom illustrations solutions
          </Text>
          <Button
            bg="#E5122E"
            _hover={{
              bg: "#71D5BD",
            }}
            color="white"
            w="121.43px"
            h="48px"
          >
            Get Started
          </Button>
        </Flex>
        <Flex
          direction="column"
          justify="space-between"
          align="flex-end"
          w="50%"
          h="100%"
          py="53px"
        >
          <ValueItem
            emoji="😍"
            title="Pixel Perfect"
            description="Scale your business and fix your design bottleneck with flat-rate,"
          />

          <Box as="span" mr="60px">
            <ValueItem
              emoji="⚡"
              title="Fast Turnarounds"
              description="Scale your business and fix your design bottleneck with flat-rate,"
            />
          </Box>
          <ValueItem
            emoji="😉"
            title="Unique and all yours"
            description="Scale your business and fix your design bottleneck with flat-rate,"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Values;
