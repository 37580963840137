import React from "react";
import { Flex, Box, Image, Heading, Text } from "@chakra-ui/react";

const OurGoal = () => {
  return (
    <Flex mt="200px" justify="center">
      <Flex
        borderRadius="34px"
        mx="25px"
        w="1280px"
        bg="#0E232D"
        h="1036px"
        direction="column"
        align="center"
        justify="center"
      >
        <Flex align="center" justify="space-between">
          <Flex w="50%">
            <Image src="https://i.ibb.co/Dw9WHBY/Group-291.png" />
          </Flex>
          <Flex ml="80px" justify="center" w="579px" direction="column">
            <Heading fontSize="50px" fontWeight="bold" color="white">
              Our Goal
            </Heading>
            <Text
              mt="16px"
              fontSize="18px"
              fontWeight="medium"
              lineHeight="28px"
              color="white"
              opacity="80%"
            >
              Scale your business and fix your design bottleneck with flat-rate,
              unlimited graphic design and custom illustrations solutionsScale
              your business and fix your design bottleneck with flat-rate,
              unlimited graphic design and custom illustrations solutionsScale
              your business and fix your design bottleneck with flat-rate,
              unlimited graphic design and custom illustrations solutions
            </Text>
          </Flex>
        </Flex>

        <Flex justify="space-between" h="289px" w="964px" mt="102px">
          <Flex justify="center" direction="column" w="406px" h="100%">
            <Heading fontSize="36px" fontWeight="bold" color="white">
              Why you should hire us
            </Heading>
            <Text
              mt="16px"
              fontSize="18px"
              fontWeight="medium"
              lineHeight="28px"
              color="white"
              opacity="80%"
            >
              Scale your business and fix your designbottleneck with flat-rate,
              unlimited graphic design and custom illustrations solutionsScale
              your business
            </Text>
          </Flex>
          <Flex justify="space-between" wrap="wrap" w="373px">
            <Box>
              <Text color="white" fontSize="41px" fontWeight="bold">
                450+
              </Text>
              <Text color="white">
                <Box as="span" fontSize="16px" fontWeight="normal">
                  We have worked on
                </Box>
                <br />
                <Box as="span" fontSize="19px" fontWeight="bold">
                  Cool Projects
                </Box>
              </Text>
            </Box>
            <Box h="105px">
              <Text color="white" fontSize="41px" fontWeight="bold">
                450+
              </Text>
              <Text color="white">
                <Box as="span" fontSize="16px" fontWeight="normal">
                  We have worked on
                </Box>
                <br />
                <Box as="span" fontSize="19px" fontWeight="bold">
                  Cool Projects
                </Box>
              </Text>
            </Box>
            <Box mt="70px" h="105px">
              <Text color="white" fontSize="41px" fontWeight="bold">
                450+
              </Text>
              <Text color="white">
                <Box as="span" fontSize="16px" fontWeight="normal">
                  We have worked on
                </Box>
                <br />
                <Box as="span" fontSize="19px" fontWeight="bold">
                  Cool Projects
                </Box>
              </Text>
            </Box>
            <Box mt="70px" h="105px">
              <Text color="white" fontSize="41px" fontWeight="bold">
                450+
              </Text>
              <Text color="white">
                <Box as="span" fontSize="16px" fontWeight="normal">
                  We have worked on
                </Box>
                <br />
                <Box as="span" fontSize="19px" fontWeight="bold">
                  Cool Projects
                </Box>
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OurGoal;
