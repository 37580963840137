import React from "react";

const DogPaws = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87.926"
      height="23.497"
      viewBox="0 0 87.926 23.497"
    >
      <g
        id="Group_204"
        data-name="Group 204"
        transform="translate(-230.398 -250.048)"
      >
        <g id="Group_203" data-name="Group 203">
          <g
            id="Group_41"
            data-name="Group 41"
            transform="translate(230.398 257.422)"
            opacity="0.3"
          >
            <g
              id="Group_38"
              data-name="Group 38"
              transform="translate(0.156 0.159)"
            >
              <g id="Group_37" data-name="Group 37">
                <path
                  id="Path_42"
                  data-name="Path 42"
                  d="M1335.06,2187.04a2.708,2.708,0,0,0-1.255,2.184c-.385,2.595.005,6.719-2.871,9.87-4.083,4.47-14.8,5.56-18.087-.153a16.147,16.147,0,0,1-1.554-11.9Z"
                  transform="translate(-1310.774 -2187.04)"
                  fill="#6b184e"
                />
              </g>
            </g>
            <g id="Group_40" data-name="Group 40" transform="translate(0 0)">
              <g id="Group_39" data-name="Group 39">
                <path
                  id="Path_43"
                  data-name="Path 43"
                  d="M1318.785,2200.664a14.212,14.212,0,0,1-1.538-.082c-3.158-.346-5.585-1.731-6.833-3.9a16.406,16.406,0,0,1-1.568-12.026l.033-.115h24.558l-.6.3a2.539,2.539,0,0,0-1.169,2.065c-.08.541-.127,1.157-.177,1.81-.19,2.5-.427,5.616-2.734,8.144C1326.646,2199.174,1322.634,2200.664,1318.785,2200.664Zm-9.667-15.8a16.1,16.1,0,0,0,1.572,11.662c1.2,2.079,3.536,3.408,6.591,3.742,4.18.457,8.907-1.063,11.24-3.617,2.233-2.447,2.465-5.5,2.651-7.953.05-.658.1-1.28.179-1.832a3.262,3.262,0,0,1,.9-2Z"
                  transform="translate(-1308.323 -2184.541)"
                  fill="#1e1b24"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_76"
            data-name="Group 76"
            transform="translate(232.267 251.047)"
          >
            <g id="Group_75" data-name="Group 75" transform="translate(0 0)">
              <path
                id="Path_57"
                data-name="Path 57"
                d="M1359.024,2091.12c-1.126,0-1.691.742-1.872,1.853-.327,2.011,0,5.2-2.436,7.645-3.462,3.463-12.553,4.307-15.341-.118-2.342-3.717-2.29-8.859-.186-11.342,4.51-5.323,12.229-5.557,17.743-2.832,2,.989,5.84,4.794,5.84,4.794Z"
                transform="translate(-1337.615 -2084.613)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_78"
            data-name="Group 78"
            transform="translate(232.457 254.196)"
            opacity="0.3"
          >
            <g id="Group_77" data-name="Group 77" transform="translate(0 0)">
              <path
                id="Path_58"
                data-name="Path 58"
                d="M1365.568,2137.328h-3.749c-1.125,0-1.689.74-1.87,1.853-.327,2.011.005,5.2-2.437,7.645-3.463,3.462-12.552,4.3-15.341-.12a11.605,11.605,0,0,1-1.569-4.108c.069.117.14.232.212.347,2.786,4.425,11.878,3.583,15.341.12,2.439-2.442,2.108-5.634,2.437-7.645a.128.128,0,0,1,.005-.033,1.732,1.732,0,0,1,2.83-1l2.963,2.508s-.342-.6-.873-1.488C1364.67,2136.438,1365.568,2137.328,1365.568,2137.328Z"
                transform="translate(-1340.602 -2133.968)"
                fill="#1e1b24"
              />
            </g>
          </g>
          <g
            id="Group_80"
            data-name="Group 80"
            transform="translate(231.241 250.048)"
          >
            <g id="Group_79" data-name="Group 79" transform="translate(0 0)">
              <path
                id="Path_59"
                data-name="Path 59"
                d="M1331.354,2089.876a14.32,14.32,0,0,1-1.6-.088c-2.876-.322-5.112-1.535-6.3-3.414-2.523-4.006-2.569-9.634-.1-12.546,4.886-5.768,13.244-5.919,18.974-3.087,2.125,1.05,5.945,4.824,6.106,4.984l1.761,1.746h-6.229c-.28,0-.7,0-.864,1-.063.384-.1.844-.141,1.331-.164,1.976-.39,4.681-2.58,6.871C1338.407,2088.649,1334.857,2089.876,1331.354,2089.876Zm3.164-18.9a12.309,12.309,0,0,0-9.6,4.173c-1.869,2.205-1.742,6.943.271,10.138.846,1.343,2.549,2.221,4.8,2.473,3.294.369,7.146-.72,8.959-2.533,1.662-1.662,1.836-3.752,1.99-5.6.044-.528.086-1.027.161-1.49a2.847,2.847,0,0,1,2.879-2.71h1.172a21.72,21.72,0,0,0-3.715-2.858A15.811,15.811,0,0,0,1334.519,2070.976Z"
                transform="translate(-1321.538 -2068.944)"
                fill="#1e1b24"
              />
            </g>
          </g>
        </g>
        <g id="Group_202" data-name="Group 202">
          <g
            id="Group_46"
            data-name="Group 46"
            transform="translate(293.21 257.422)"
            opacity="0.3"
          >
            <g
              id="Group_43"
              data-name="Group 43"
              transform="translate(0.672 0.159)"
            >
              <g id="Group_42" data-name="Group 42">
                <path
                  id="Path_44"
                  data-name="Path 44"
                  d="M2303.482,2187.04a2.707,2.707,0,0,1,1.255,2.184c.385,2.595,0,6.719,2.871,9.87,4.083,4.47,14.8,5.56,18.087-.153a16.148,16.148,0,0,0,1.554-11.9Z"
                  transform="translate(-2303.482 -2187.04)"
                  fill="#6b184e"
                />
              </g>
            </g>
            <g id="Group_45" data-name="Group 45" transform="translate(0 0)">
              <g id="Group_44" data-name="Group 44">
                <path
                  id="Path_45"
                  data-name="Path 45"
                  d="M2307.605,2200.664c-3.849,0-7.86-1.49-9.971-3.8-2.307-2.528-2.544-5.642-2.734-8.144-.049-.653-.1-1.269-.177-1.81a2.54,2.54,0,0,0-1.169-2.065l-.6-.3h24.557l.034.115a16.407,16.407,0,0,1-1.569,12.026c-1.248,2.169-3.675,3.554-6.833,3.9A14.212,14.212,0,0,1,2307.605,2200.664Zm-13.469-15.8a3.263,3.263,0,0,1,.9,2c.082.552.129,1.174.179,1.832.187,2.454.419,5.507,2.651,7.954,2.332,2.553,7.058,4.074,11.239,3.617,3.054-.334,5.4-1.663,6.591-3.742a16.1,16.1,0,0,0,1.572-11.662Z"
                  transform="translate(-2292.954 -2184.541)"
                  fill="#1e1b24"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_82"
            data-name="Group 82"
            transform="translate(291.608 251.047)"
          >
            <g id="Group_81" data-name="Group 81" transform="translate(0 0)">
              <path
                id="Path_60"
                data-name="Path 60"
                d="M2271.59,2091.12c1.126,0,1.691.742,1.872,1.853.327,2.011,0,5.2,2.436,7.645,3.463,3.463,12.553,4.307,15.341-.118,2.342-3.717,2.29-8.859.186-11.342-4.51-5.323-12.229-5.557-17.743-2.832-2,.989-5.84,4.794-5.84,4.794Z"
                transform="translate(-2267.841 -2084.613)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_84"
            data-name="Group 84"
            transform="translate(289.129 250.048)"
          >
            <g id="Group_83" data-name="Group 83" transform="translate(0 0)">
              <path
                id="Path_61"
                data-name="Path 61"
                d="M2247.816,2089.879c-3.5,0-7.053-1.227-9.032-3.206-2.191-2.191-2.416-4.9-2.58-6.871-.04-.487-.079-.947-.141-1.331-.162-1-.584-1-.864-1h-6.229l1.761-1.746c.162-.16,3.982-3.935,6.106-4.984,5.731-2.831,14.087-2.681,18.974,3.087,2.468,2.913,2.422,8.541-.1,12.546-1.184,1.879-3.42,3.091-6.3,3.414A14.311,14.311,0,0,1,2247.816,2089.879Zm-13.79-14.446h1.172a2.847,2.847,0,0,1,2.879,2.71c.075.463.117.962.161,1.49.154,1.844.328,3.935,1.99,5.6,1.813,1.813,5.665,2.9,8.959,2.533,2.247-.252,3.95-1.13,4.8-2.473,2.013-3.2,2.139-7.933.271-10.138-4.209-4.968-11.493-5.057-16.512-2.576A21.726,21.726,0,0,0,2234.026,2075.432Z"
                transform="translate(-2228.97 -2068.947)"
                fill="#1e1b24"
              />
            </g>
          </g>
          <g
            id="Group_86"
            data-name="Group 86"
            transform="translate(291.704 254.196)"
            opacity="0.3"
          >
            <g id="Group_85" data-name="Group 85" transform="translate(0 0)">
              <path
                id="Path_62"
                data-name="Path 62"
                d="M2269.343,2137.328h3.749c1.125,0,1.689.74,1.87,1.853.327,2.011-.005,5.2,2.437,7.645,3.463,3.462,12.552,4.3,15.341-.12a11.6,11.6,0,0,0,1.569-4.108c-.069.117-.14.232-.212.347-2.786,4.425-11.878,3.583-15.341.12-2.439-2.442-2.108-5.634-2.437-7.645a.123.123,0,0,0,0-.033,1.731,1.731,0,0,0-2.83-1l-2.963,2.508s.342-.6.873-1.488C2270.241,2136.438,2269.343,2137.328,2269.343,2137.328Z"
                transform="translate(-2269.343 -2133.968)"
                fill="#1e1b24"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DogPaws;
